// COLORS
$black: #0f0f0f;
$gray: #9d9d9d;
$very-light-gray: #f2f4f7;
$medium-gray: #606060;
$medium-gray2: #5a5a5a;
$dark-gray: #313131;
$dark-gray2: #7e7e7e;
$white: #ffffff;
$green: #8ce04a;
$dark-green: #10ac84;
$red: #ff5349;
$dark-red: #461e1e;
$yellow: #ffc404;

// CUSTOM BRAND COLORS
$primary-brand-color: #10ac84;
$secondary-brand-color: #2e86de;
$primary-brand-color-hover: #237960;
$primary-outline-color: #c8d6e5;
$primary-bg-color: $white;
$secondary-bg-color: $white;
$hr-color: $dark-gray2;
$light-gray: #c8d6e5;
$gray: #8395a7;
$medium-gray: #576574;
$dark-gray: #222f3e;
$black: #11181f;
$redError: #ee5253;
$primary-brand-color-opacity: rgba(16, 172, 132, 0.5);

// Used when a color is primary for WCRA and secondary for RET
$wcra-primary-ret-secondary-brand-color: $secondary-brand-color;

// CUSTOM LAYOUT/FONT COLORS
$primary-label-color: $gray;
$primary-input-color: $medium-gray;
$primary-placeholder-color: $light-gray;
$disabled-button-color: rgba(16, 172, 132, 0.5);
$btn-focus-color: $white;
$secondary-btn-focus-color: $white;
$banner-text-color: $white;
$primary-btn-bg: $primary-brand-color;
$primary-btn-hover: #237960;
$primary-btn-border: transparent;
$primary-btn-text-color: $white;
$secondary-btn-bg: $dark-gray;
$secondary-btn-hover: $black;
$secondary-btn-border: transparent;
$back-button-color: $primary-brand-color;
$primary-text-color: $dark-gray;
$select-text-color: $dark-gray;
$background-color: $primary-bg-color;
$event-result-border: $light-gray;
$event-title-color: $dark-gray;
$select-value-color: $medium-gray;
$select-value-bg-color: $white;
$select-value-bg-hover: $light-gray;
$select-value-selected: $primary-brand-color;
$event-result-border-hover: $primary-brand-color;
$agreements-label-color: $gray;
$error-msg-bg-color: $redError;
$error-message-text-color: $primary-bg-color;
$saved-cards-hover-color: $medium-gray;
$entry-preference-bg-color: $white;
$entry-preference-bg-color: $white;
$dashboard-options-color: $medium-gray;
$profile-name-color: $dark-gray;
$profile-location-color: $medium-gray;
$profile-discipline-color: $medium-gray;
$disabled-pref-color: $redError;
$current-entries-color: $medium-gray;
$event-pref-border: $light-gray;
$event-pref-selected-color: $primary-brand-color;
$header-custom-color: #11181f;
$pref-green-color: $primary-brand-color;
$disabled-opacity: 1;
$checkbox-label-color: $dark-gray;
$home-subheader-color: $gray;
$home-signinwith-color: $dark-gray;
$section-title-padding: 0;
$edit-card-color: $redError;
$remove-card-color: $dark-gray;
$edit-card-text-color: $secondary-brand-color;
$date-item-default-color: $light-gray;
$checkbox-inactive-border: $light-gray;
$checkmark-color: $white;
$checkbox-disabled-border: rgba(16, 172, 132, 0.5);
$multi-select-container-bg: $white;
$loading-spinner-color: $primary-brand-color;
$remove-preference-color: $primary-brand-color;
$container-mobile-padding: 12px;
$entry-cant-compete-border-color: $red;
$review-selected-discipline-bg-color: $white;
$preference-icon-color: $dark-gray;
$preferences-bg-color: $very-light-gray;
$preference-card-bg-color: $white;
$date-item-color: $light-gray;
$preference-card-opt-out-color: $redError;
$preference-card-opt-in-color: $primary-brand-color;
$entries-count-bg-color: $light-gray;
$preference-card-opt-out-date-color: $white;
$discipline-item-bg: $white;
$alliance-partner-primary-color: #222f3e;
$alliance-partner-secondary-color: #2f3d52;
$alliance-tag-color: rgba(46, 134, 222, 0.2);
$alliance-label-color: #222f3e;
$event-search-card-bg-color: $white;
$event-filter-color: $white;
$multi-select-bg-color: $dark-gray;
$multi-select-color: $white;
$multi-select-item-bg-color: $white;
$event-filter-color: $white;
$event-type-hover-color: $white;
$discipline-modal-border: $light-gray;
$entry-partner-search-option-font-color: $white;
$entry-partner-search-place-holder: $light-gray;
$hr-modal-color: $light-gray;
$entry-partner-invite-success: $dark-green;
$view-cart-btn-text: $white;
$view-cart-btn-background: $primary-brand-color;
$info-icon-color: $white;
$entry-discipline-modal-arrow-active: $black;
$entry-discipline-modal-arrow-disabled: $gray;
$optional-label: $dark-green;

// FONTS
$primary-font: 'Barlow', san-serif;

// BREAKPOINTS
$tablet: medium;
$desktop: large;
$phone-width: 768px;
$tablet-width: 1024px;
$narrow-phone-smaller-width: 'only screen and (max-width : 320px)';
$narrow-phone-width: 'only screen and (max-width : 399px)';
$narrow-phone-bigger-width: 'only screen and (max-width : 415px)';

// LAYOUT
$footer_offset_bottom: 220px;
$header-height: 80px;

// OTHER
$transition_speed_mid: 0.3s;
$transition_speed_long: 0.5s;

body,
html {
  height: 100%;
  background-color: unset;
}

.bg-home,
.bg-events,
.bg-dashboard,
.bg-leaderboard,
.bg-nominate,
.bg-user {
  background: url('../../assets/img/background-images/bg-ret.jpg');
}

.grid-x > .ret-login-form {
  margin-top: 10px;

  @media (min-width: 64em) {
    width: calc(50% + 100px);
    padding: 35px 50px;
  }

  @media (max-width: 767px) {
    margin-top: 50px;
  }

  .section-title {
    font-size: 32px;
    padding: 0;
    line-height: 1.18em;
    text-align: center;
    letter-spacing: inherit !important;

    @media (max-width: 767px) {
      text-align: left;

      &.login-header {
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }
  .mobile-only .section-title {
    font-size: 28px;
  }
}

.ret-logo {
  &.home-logo {
    padding-top: 50px;
    width: 200px;
  }
}

.layout-wrapper .desktop-menu-wrapper {
  background-color: $header-custom-color;
  display: block;
  vertical-align: top;
  @media (max-width: $phone-width) {
    height: 50px;
    padding: 10px 0;
  }
}

.footer .mobile-menu-wrapper {
  background-color: $primary-input-color;
  li:nth-of-type(2n) {
    padding-left: 50px;
  }
}

.desktop-menu-wrapper .ret-logo {
  width: 50px;
  margin-left: -50px;

  @media (max-width: 1280px) {
    margin-left: 0;
  }
  @media (max-width: $phone-width) {
    width: 30px;
  }
}

.powered-by-logo {
  width: 180px;
  padding-left: 20px;

  @media (max-width: $phone-width) {
    width: 100px;
    padding-left: 10px;
  }
}

.grid-x > .theme-content-container,
.theme-content-container {
  @media (min-width: 64em) {
    width: calc(50% + 100px);
    padding: 50px;
  }
}

.entry-confirmation.theme-content-container {
  .confirmation-text-container .confirmation-title {
    color: $dark-gray;

    &.total-paid {
      color: $primary-brand-color !important;
    }
  }
  .event-result {
    &:hover {
      color: $dark-gray !important;
    }
  }

  .event-result {
    border-bottom: transparent !important;
  }
}

.entry-add-buddy-card.theme-content-container {
  .confirmation-text-container .confirmation-title {
    color: $dark-gray;

    &.total-paid {
      color: $primary-brand-color !important;
    }
  }
}

.event-result {
  .event-dates {
    padding-bottom: 5px !important;
  }

  .event-division,
  .partner-badge {
    display: none !important;
  }
}

.section-title, .mobile-title, .discipline-title {
  letter-spacing: inherit !important;
  .center {
    padding-bottom: 0 !important;
  }
}

.mobile-only {
  .section-title, .mobile-title, .discipline-title {
    font-size: 28px !important;
  }
}

.division-and-partner-container {
  display: none !important;
}

.division-rank {
  display: none !important;
}

.Select .Select-option.is-selected {
  color: $btn-focus-color;
}

@mixin pref-selected-pseduo-element {
  background: url('../../assets/img/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: 2px 5px;
  background-size: 17px 10px;
  border: 2px solid $black;
  background-color: $primary-brand-color;
}

@mixin out-selected-pseduo-element {
  border: 2px solid $black;
  background-color: $red;
}

@mixin pref-disabled-pseduo-element {
  background: url('../../assets/img/icons/x.svg');
  background-repeat: no-repeat;
  background-position: 4px 3px;
  background-size: 8px 9px;
  border: 2px solid $black;
  background-color: $disabled-pref-color;
}

.athlete-container {
  a {
    color: $primary-brand-color;

    &:hover {
      color: $primary-brand-color-hover;
    }
  }
}

.competition-list-select {
  .discipline-name-area {
    width: calc(100% + 100px);
    padding-left: 72px;
    margin-left: -50px;

    @media (max-width: 1023px) {
      width: calc(100% + 44px);
      padding-left: 34px;
      margin-left: -22px;
    }
  }
}

.card-data-section.card-data-section-entry {
  margin-bottom: 30px !important;
}

.zip-code-column {
  margin-bottom: 15px;
  width: 50%;
}

.cvc-column {
  border-left: 0px !important;
  margin-left: 6px;
  width: calc(50% - 3px) !important;
}

.exp-date-column {
  width: calc(50% - 3px) !important;
}

.enter-landing-view {
  .selected-disciplines-container .selected-discipline {
    background: $discipline-item-bg;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  }
  .multi-select-wrapper
    .multi-select-container
    .add-disc-container
    .add-disc-button {
    background: $dark-gray;
    color: $btn-focus-color;
  }
}

.home-subheader {
  padding: 0 50px 25px;
}

.bg-home .home-page-container {
  padding-top: 15vh;

  @media (max-width: $phone-width) {
    padding-top: 14px;
  }

  .home-title-yellow {
    padding-top: 60px;
    color: $alliance-partner-primary-color;
    font-size: 24px;
    font-weight: bold;
  }

  .home-subheader {
    letter-spacing: 2px;
    color: $alliance-partner-secondary-color;
  }
}

header {
  .wcra-logo.ret-logo {
    width: 100px;
  }

  @media (max-width: $phone-width) {
    .wcra-logo.ret-logo {
      width: 68px;
      margin-left: 22px;
    }
  }
}

.ret-logo.home-size {
  width: 230px;
}

.discipline-entry-limit {
  color: $primary-input-color;
}

.home-page-container .theme-content-container {
  width: 773px;

  @media (max-width: 718px) {
    .home-buttons a:nth-of-type(2) {
      margin: 0 20px;
    }
  }
}

.ReactModalPortal {
  .entry-event-discipline-modal .modal-header {
    border-bottom: 2px solid $discipline-modal-border;
  }
}

.modal-content {
  color: $dark-gray;
}

.selected-disciplines-container .selected-discipline.list-item {
  button svg {
    stroke: $secondary-brand-color;
  }
}

@mixin partner-options-shadow {
  box-shadow: 0px 2px 5px $medium-gray2;
}

.cart-icon {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.error-message {
  color: $redError;
}

.Select-placeholder {
  color: $primary-outline-color;
}

.warning-icon {
  img {
    filter: invert(70%) sepia(4%) saturate(100) hue-rotate(-24deg)
      brightness(100%) contrast(100%);
  }
}
